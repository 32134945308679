import axios from '../axios'

export default {
  async downloadModuleDashboard({ commit }) {
    // вынесла это в отдельный экшн, потому что при всех доступах и роли -Главный, префикс получается default и страница с модулями не отображается.
    // не знаю что это за запрос, поставь, плиз, куда он тебе нужен
    try {
      const response = await axios.get('integration/module/dashboard')
      commit('setModules', response)
    } catch (e) {
      if (!e.cancelled) {
        commit('Notify/setError', 'При загрузке списка модулей произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async downloadListModules({ state, commit }, payload) {
    const { args, pagination } = payload
    try {
      const response = await axios.get(`admin/modules${args}`)
      if (pagination) {
        state.modules.push(...response.data)
        commit('setModules', state.modules)
      } else {
        commit('setModules', response.data)
      }
      commit('setPerPage', response.per_page)
      commit('setTotalRows', response.total)
    } catch (e) {
      if (!e.cancelled) {
        commit('Notify/setError', 'При загрузке списка модулей произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async moduleShow({ commit }, id) {
    try {
      const response = await axios.get(`admin/modules/${id}`)
      commit('setModule', response)
      return true
    } catch (e) {
      if (!e.cancelled) {
        // commit('Notify/setError', 'При загрузке списка модулей произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async downloadListWarehouseInModule({ commit }, payload) {
    const { name, id, page } = payload
    const search = name ? `search=${name}&` : ''
    try {
      const response = await axios.get(`admin/warehouses?${search}filter[modules][0]=${id}&page=${page}`)
      commit('setWarehouseInModule', response.data)
      commit('setWarehousePerPage', response.per_page)
      commit('setWarehouseTotalRows', response.total)
      return true
    } catch (e) {
      if (!e.cancelled) {
        commit('Notify/setError', 'При загрузке списка модулей произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async downloadListAvailableModules({ commit }, payload) {
    // TODO: Добавить отмену запроса с помощью AbortController

    try {
      const args = payload && payload.args ? payload.args : ''
      const response = await axios.get(`storekeeper/modules?${args}`)
      commit('setAvailableModules', response.data)
    } catch (e) {
      if (!e.cancelled) {
        commit('Notify/setError', 'При загрузке списка модулей произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async uploadAssignedModule({ state, commit, rootGetters }) {
    try {
      const userRfid = rootGetters['Employee/scannedEmployeePass'].RFID
      const moduleRfid = state.availableModule.RFID
      const data = new FormData()
      data.set('user', userRfid)
      data.set('module', moduleRfid)
      const response = await axios.post('storekeeper/modules', data)
      return {
        moduleName: response.title,
        userName: `${response.user.last_name} ${response.user.first_name}`,
      }
    } catch (e) {
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  clearListModules({ commit }) {
    commit('setModules', [])
    commit('setTotalRows', undefined)
    commit('setPerPage', undefined)
  },
  updateModule({ commit, state }, data) {
    const foundModule = state.modules.find(item => item.id === data.id)
    if (!foundModule) return
    commit('updateModule', data)
    commit('Notify/setInfo', `Обновлена информация о модуле "${data.title}"`, { root: true })
  },
  async uploadNewModule({ commit }, model) {
    try {
      const response = await axios.post('/admin/modules', model)
      commit('setSelectedModule', {
        ...response,
      })
      const name = response.title ? `${response.title}` : ''
      commit('Notify/setSuccess', `Модуль ${name} успешно добавлен`, { root: true })
      return true
    } catch (e) {
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async uploadDeletedModule({ state, commit }) {
    try {
      const id = state.selectedModule.id
      await axios.delete(`/admin/modules/${id}`)

      return true
    } catch (e) {
      commit('Notify/setError', 'При удалении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async uploadEditedModule({ commit }, payload) {
    try {
      const { id, model } = payload
      const response = await axios.put(`/admin/modules/${id}`, model)
      const name = response.title ? `${response.title}` : ''
      commit('Notify/setSuccess', `Модуль ${name} успешно изменен`, { root: true })
      commit('setSelectedModule', response)
      return response
    } catch (e) {
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async searchModule({ commit }, id) {
    try {
      await axios.get(`/module/${id}/detect`)
      commit('Notify/setSuccess', 'Модуль найден', { root: true })
    } catch (e) {
      commit('Notify/setError', 'При поиске произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async registryModule({ commit }, payload) {
    try {
      const response = await axios.get(`modules/${payload.id}/registry`)
      commit('setRegistryModule', response)
    } catch (e) {
      commit('Notify/setError', 'При загрузке реестра произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
}
