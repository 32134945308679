export default {
  SET_NOTIFICATIONS: (state, payload) => (state.notifications = payload),
  setReadNotification: (state, payload) => {
    const notification = state.notifications.find(n => n.id === payload.id)
    if (notification) {
      notification.read_at = payload.read_at
    }
  },
  setClickNotification: (state, payload) => (state.firstClick = payload),
  setGanttIsBlock: (state, value) => (state.ganttIsBlock = value),
  setImportIsBlock: (state, value) => (state.importIsBlock = value),
  setNeedShowImportTab: (state, value) => (state.needShowImportTab = value),
  setTotalNoRead: (state, value) => (state.totalNoRead = value),
  setPerPage(state, data) {
    state.perPage = data
  },
  setTotalRows(state, data) {
    state.totalRows = data
  },
}
