import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  modules: [],
  module: undefined,
  warehouseInModule: [],
  registryModule: [],
  selectedModule: undefined,
  availableModules: [],
  availableModule: null,
  needReloadModules: false,
  needOpenModalForNewModule: false,
  perPage: undefined,
  warehousePerPage: undefined,
  warehouseTotalRows: undefined,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
