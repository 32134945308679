export default {
  setDisposal(state, obj) {
    state.disposalList.forEach(item => {
      if (item.id === obj.id) {
        item.count = obj.filter(el => el.id).count
      }
    })
  },
  setDisposalList(state, value) {
    state.disposalList = value
  },
  setDisposalCount(state, value) {
    const map = value.map(item => item.count)
    const reducer = (accumulator, curr) => accumulator + curr
    state.disposalCount = map.reduce(reducer)
  },
}
