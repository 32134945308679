export const TASK_LIST_FILTER_OPTIONS = {
  categories: [
    { text: 'Входящие', value: 'inbox' },
    { text: 'Исходящие', value: 'outbox' },
    { text: 'На контроле', value: 'on_control' },
    { text: 'На соисполнении', value: 'on_co-execution' },
  ],
  manager_categories: [
    { text: 'Рабочие задания отдела', value: 'dept_tasks' },
    { text: 'Рабочие задания от отдела', value: 'tasks_from_the_dept' },
    { text: 'Архив рабочему заданию', value: 'archive_of_tasks' },
  ],
  states: [
    { text: 'Открытые', value: 'open' },
    { text: 'В работе', value: 'progress' },
    { text: 'Готовы', value: 'done' },
  ],
}

export const REQUIRED_TASK_FIELDS = ['baseInfo', 'implementer', 'date']

export const INSTRUMENT_STATUSES = ['balance', 'issued', 'lost', 'junk', 'in_module', 'write-off', 'use']
