import axios from '@/store/axios'

export default {
  async downloadListRoles({ commit }, payload) {
    const search = payload ? `${payload.args}` : ''
    try {
      const response = await axios.get(`/users/roles${search}`)
      commit('setRoles', response.data)
      commit('setPerPage', response.per_page)
      commit('setTotalRows', response.total)
    } catch (e) {
      if (!e.cancelled) {
        commit('Notify/setError', 'При загрузке списка ролей произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async createRole({ commit }, payload) {
    try {
      const response = await axios.post('/users/roles', payload)
      commit('Notify/setSuccess', 'Роль успешно сохранена.', { root: true })
      commit('setNewRole', response)
      return response
    } catch (e) {
      commit('Notify/setError', 'При создании роли произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async deleteRole({ commit }, id) {
    try {
      await axios.delete(`/users/roles/${id}`)
      commit('Notify/setSuccess', 'Роль успешно удалена.', { root: true })
      return true
    } catch (e) {
      commit('Notify/setError', 'При удалении роли произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async updateRole({ commit }, payload) {
    try {
      const id = payload.id
      delete payload.id
      await axios.put(`/users/roles/${id}`, payload)
      commit('Notify/setSuccess', 'Роль успешно обновлена.', { root: true })
      return true
    } catch (e) {
      commit('Notify/setError', 'При обновлении роли произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
}
