import { updateShownColumnsKeys } from '@/Utils/helpers'

export default {
  setColumns(state, shown) {
    for (const column of state.columns) {
      if (column.fixed) continue
      column.show = shown.includes(column.key)
    }
    updateShownColumnsKeys('extinguisher', shown)
  },
  setExtinguishers(state, data) {
    state.extinguishers = data
  },
  setPerPage(state, data) {
    state.perPage = data
  },
  setTotalRows(state, data) {
    state.totalRows = data
  },
  setScannedExtinguishers(state, data) {
    state.scannedExtinguishers = data
  },
}
