export default {
  columns: state => state.columns,
  shownColumns: state => state.columns.filter(column => column.fixed || column.show),
  extinguishers: state => state.extinguishers,
  extinguisher: state => state.extinguisher,
  needReloadExtinguishers: state => state.needReloadExtinguishers,
  perPage: state => state.perPage,
  totalRows: state => state.totalRows,
  scannedExtinguishers: state => state.scannedExtinguishers,
}
