import actions from './actions'
import mutations from './mutations'

const state = {
  checkpoints: [],
  selectedCheckpoint: null,
  needOpenModalForNewCheckpoint: false,
  perPage: null,
  totalRows: null,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
