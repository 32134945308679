import { getCurrentInstance } from 'vue'

export default {
  install(app) {
    app.provide('ctx', () => {
      const ctx = getCurrentInstance().ctx
      return ctx
    })
  },
}
