import axios from '../axios'

export default {
  async fetchKpiData({ commit }, paramObj) {
    try {
      const { 0: data } = await axios.get(
        'integration/dashboard/' + paramObj.page + '?' + `project_id=${paramObj.projectId}`,
      )
      commit('setKpiData', paramObj.page === 'kpi' ? data.amount : data)
    } catch (e) {
      console.log(e)
    }
  },
  async fetchTableData({ commit }) {
    try {
      const { data } = await axios.get('integration/dashboard/list')
      commit('setTableData', data[0])
    } catch (e) {
      console.log(e)
    }
  },
}
