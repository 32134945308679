import axios from '@/store/axios'

export default {
  async downloadListEquipments({ commit }, payload) {
    try {
      const response = await axios.get(`repairs/equipment/list${payload.args}`)
      commit('setEquipments', response.data)
      commit('setPerPage', response.per_page)
      commit('setTotalRows', response.total)
    } catch (e) {
      commit('Notify/setError', 'При загрузке списка обрудования произошла ошибка. Попробуйте позже.', { root: true })
      throw e
    }
  },
  async uploadDeletedEquipment({ state, commit }) {
    try {
      const id = state.selectedEquipment.id
      await axios.delete(`/repairs/equipment/${id}`)
      commit('Notify/setSuccess', 'Оборудование успешно удалёно.', { root: true })
      return true
    } catch (e) {
      commit('Notify/setError', 'При удалении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async uploadEditedEquipment({ state, commit }, payload) {
    try {
      const { id, model } = payload
      const data = new FormData()

      for (const [key, value] of Object.entries(model)) {
        if (value !== undefined) {
          data.set(key, String(value))
          continue
        }

        if ((key === 'repair_frequency' || key === 'model') && value === undefined) {
          data.set(key, '')
          continue
        }

        data.set(key, String(value))
      }

      const response = await axios.put(`/repairs/equipment/${id}`, data)
      const updatedEquipment = {
        ...state.selectedEquipment,
        ...response,
      }

      commit('setSelectedEquipment', updatedEquipment)
      commit('Notify/setSuccess', 'Оборудование успешно обновлёно.', { root: true })
      return true
    } catch (e) {
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async uploadNewEquipment({ commit }, model) {
    try {
      const data = new FormData()

      for (const [key, value] of Object.entries(model)) {
        if (value) {
          if (value?.value !== undefined) {
            data.set(key, String(value.value))
            continue
          }
          data.set(key, String(value))
        }
      }

      const response = await axios.post('/repairs/equipment', data)
      commit('setSelectedEquipment', { ...response })
      commit('Notify/setSuccess', `Оборудование ${response.name} успешно добавлено`, { root: true })
      return true
    } catch (e) {
      console.log('e', e)
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async equipmentShow({ commit }, equipmentId) {
    try {
      const response = await axios.get(`/repairs/equipment/${equipmentId}`)
      commit('setEquipmentShow', response)
    } catch (e) {
      commit('Notify/setError', 'При загрузке оборудования произошла ошибка. Попробуйте позже.', { root: true })
      throw e
    }
  },
}
