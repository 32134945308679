import actions from './actions'
import mutations from './mutations'

const state = {
  instruments: null,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
