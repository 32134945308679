import axios from '../axios'

export default {
  async downloadStatusHistory({ state, commit }) {
    const { filters, search } = state
    const dateFromParam = filters.dateFrom ? `date_from=${filters.dateFrom}` : ''
    const dateToParam = filters.dateTo ? `date_to=${filters.dateTo}` : ''
    const responsibleParam = filters.responsible ? `responsible=${filters.responsible}` : ''
    const searchParam = search ? `search=${search}` : ''
    const params = [dateFromParam, dateToParam, searchParam, responsibleParam].filter(item => !!item).join('&')
    try {
      const response = await axios.get(`/storekeeper/histories?${params}`)
      commit('setStatusHistory', response.data)
    } catch (e) {
      commit('Notify/setError', 'При загрузке истории статусов произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
}
