export default {
  setEmployees(state, value) {
    state.employees = value
  },
  setSelectedEmployee(state, value) {
    state.selectedEmployee = value
  },
  setPerPage(state, value) {
    state.perPage = value
  },
  setTotalRows(state, value) {
    state.totalRows = value
  },
  setEmployeesWithoutRfid(state, value) {
    state.employeesWithoutRfid = value
  },
  setEmployeeWithoutRfid(state, value) {
    state.employeeWithoutRfid = value
  },
  setScannedNewRfidPass(state, value) {
    state.scannedNewRfidPass = value
  },
  setScannedEmployeePass(state, data) {
    state.scannedEmployeePass = data
  },
  setAvailableResponsibleList(state, data) {
    state.availableResponsibleList = data
  },
  setAvailableResponsibleMeta(state, { total, perPage }) {
    state.responsibleTotalRows = total
    state.responsiblePerPage = perPage
  },
}
