import axios from '@/store/axios'

export default {
  async downloadListVideoSources({ commit }, payload) {
    try {
      commit('setVideoSourceLoading', true)

      const response = await axios.get(`/horizon/video-source${payload.args}`)

      if (response) {
        commit('setVideoSources', response.data)
        commit('setTotal', response.total)
        commit('setTotalPage', response.total_pages)
        commit('setPerPage', response.per_page)
      }
    } catch (error) {
      console.error(error)
    } finally {
      commit('setVideoSourceLoading', false)
    }
  },
  async sendCombinationData({ commit }, payload) {
    try {
      commit('setSendDataLoading', true)
      await axios.post('/horizon/video-source/training', { cameras_id: payload.cameras_id })
    } catch (error) {
      console.error(error)
    } finally {
      commit('setSendDataLoading', false)
    }
  },
  async saveCombinationData({ commit }, payload) {
    try {
      commit('setSaveDataLoading', true)
      await axios.post('/horizon/video-source/bind', payload)
    } catch (error) {
      console.error(error)
    } finally {
      commit('setSaveDataLoading', false)
    }
  },
  async getCombinationData({ commit }, payload) {
    try {
      commit('setDataDownloading', true)
      const response = await axios.get('/horizon/video-source/bind/show', {
        params: {
          'cameras_id[0]': payload.cameras_id[0],
          'cameras_id[1]': payload.cameras_id[1],
        },
      })

      return response.origin_data
    } catch (error) {
      console.error(error)
    } finally {
      commit('setDataDownloading', false)
    }
  },
}
