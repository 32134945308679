<script>
export default {
  name: 'DefaultIcon',
  props: ['size', 'color'],
}
</script>

<template>
  <svg
    :width="size || 24"
    :height="size || 24"
    :style="{ 'min-height': size || 24 + 'px !important' }"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4498 6.71997L6.72974 3L3.00977 6.71997"
      stroke="#3F8CFF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M6.72949 21V3" stroke="#3F8CFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M13.5498 17.28L17.2698 21L20.9898 17.28"
      stroke="#3F8CFF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M17.2695 3V21" stroke="#3F8CFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>
