export default {
  selectInstrument(state, item) {
    state.selectedInstruments.push(item)
  },
  unselectInstrument(state, id) {
    state.selectedInstruments = state.selectedInstruments.filter(item => item.id !== id)
  },
  setInstruments(state, value) {
    state.instruments = value
  },
  setCurrentPage(state, value) {
    state.currentPage = value
  },
  setPerPage(state, value) {
    state.perPage = value
  },
  setNeedReloadList(state, value) {
    state.needReloadList = value
  },
}
