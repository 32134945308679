export default {
  instruments: state => state.instruments,
  preorderIsEmpty: state => !state.instruments.length,
  perPage: state => state.perPage,
  totalRows: state => state.totalRows,
  selectedInstruments: state => state.selectedInstruments,
  preorderNotSelected: state => !state.selectedInstruments.length,
  addedInstrument: state => state.addedInstrument,
  needReloadList: state => state.needReloadList,
}
