import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = {
  notifications: null,
  firstClick: false,
  perPage: undefined,
  totalRows: undefined,
  ganttIsBlock: false,
  importIsBlock: false,
  needShowImportTab: false,
  totalNoRead: undefined,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
