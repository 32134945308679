import { updateShownColumnsKeys } from '@/Utils/helpers'

export default {
  setColumns(state, shown) {
    for (const column of state.columns) {
      if (column.fixed) continue
      column.show = shown.includes(column.key)
    }
    updateShownColumnsKeys('warehouse', shown)
  },
  setInstruments(state, value) {
    state.instruments = value
  },
  setWarehouseShow(state, value) {
    state.warehouseShow = value
  },
  setInspectorInstrument(state, value) {
    state.inspectorInstrument = value
  },
  setInspectorInstrumentInModal(state, value) {
    if (value.length) {
      state.inspectorInstrumentInModal = [...state.inspectorInstrumentInModal, ...value]
    }
  },
  deleteInspectorInstrumentInModal(state, value) {
    state.inspectorInstrumentInModal = [...value]
  },
  setInstrumentNameFilter(state, value) {
    state.instrumentNameFilter = value
  },
  setPerPage(state, value) {
    state.perPage = value
  },
  setTotalRows(state, value) {
    state.totalRows = value
  },
  setNeedOpenModalForNewInstrument(state, value) {
    state.needOpenModalForNewInstrument = value
  },
  setSelectedInstrument(state, value) {
    state.selectedInstrument = value
  },
  setVariantView(state, value) {
    state.viewFullScreen = value
  },
  setRFIDById(state, warehouse) {
    const tool = state.instruments.find(tool => tool.id === warehouse.id)
    if (tool) {
      tool.RFID = warehouse.RFID
    }
  },
  setRegistryForInstrument(state, value) {
    if (value.module && state.instruments) {
      state.instruments.find(instrument => instrument.id === value.warehouseId).registry = {
        title: value.module.title,
        id: value.module.id,
      }

      state.selectedInstrument = {
        ...state.selectedInstrument,
        registry: {
          title: value.module.title,
          id: value.module.id,
        },
      }
    }
  },
  setUpdateRegistryStatus(state, value) {
    state.isUpdateRegistryStatus = value
  },
}
