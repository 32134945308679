import axios from '../axios'

export default {
  async downloadListVideoSources({ state, commit }, payload) {
    try {
      const response = await axios.get(`/horizon/video-source${payload.args}`)
      if (payload.pagination) {
        state.videoSources.push(...response.data)
        commit('setVideoSources', state.videoSources)
      } else {
        commit('setVideoSources', response.data)
      }
      commit('setPerPage', response.per_page)
      commit('setTotalRows', response.total)
      commit('setTotalPage', response.total_pages)
      return response.data
    } catch (e) {
      if (e.handled || e.logout) return
      commit('Notify/setError', 'При загрузке списка произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async listDomedVideoSources({ commit }, payload) {
    try {
      const response = await axios.get(`/horizon/video-source${payload.args}&filter[domed]=0&per_page=10000`)
      // const filtredResponse = response.data.filter(el => !el.domed)
      commit('setDomedVideoSources', response.data)
      commit('setDomedTotalRows', response.total)
      return response.data
    } catch (e) {
      if (e.handled || e.logout) return
      commit('Notify/setError', 'При загрузке списка произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async uploadNewVideoSource({ commit }, model) {
    try {
      if (model.video_source) {
        model.video_source_ids = [model.video_source]
        delete model.video_source
      }

      const response = await axios.post('/horizon/video-source', model)
      commit('setSelectedVideoSource', {
        ...response,
      })
      commit('Notify/setSuccess', 'Источник успешно добавлен', { root: true })
      return true
    } catch (e) {
      if (e.handled || e.logout) return
      console.log(e)
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async uploadDeletedVideoSource({ state, commit }) {
    try {
      const id = state.selectedVideoSource.id
      await axios.delete(`/horizon/video-source/${id}`)
      return true
    } catch (e) {
      if (e.handled || e.logout) return
      commit('Notify/setError', 'При удалении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async uploadEditedVideoSource({ state, commit }, payload) {
    try {
      const { id, model } = payload
      if (model.video_source) {
        model.video_source_ids = [model.video_source]
        delete model.video_source
      }
      const response = await axios.put(`/horizon/video-source/${id}`, model)
      const updatedVideoSource = {
        ...state.selectedVideoSource,
        ...response,
      }
      commit('Notify/setSuccess', 'Изменения сохранены', { root: true })
      commit('setSelectedVideoSource', updatedVideoSource)
      return true
    } catch (e) {
      if (e.handled || e.logout) return
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
}
