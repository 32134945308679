import axios from '@/store/axios'

export default {
  async downloadListTeams({ commit }, payload) {
    const search = payload ? `${payload.args}` : ''
    try {
      const response = await axios.get(`/teams${search}`)
      commit('setTeams', response.data)
      commit('setPerPage', response.per_page)
      commit('setTotalRows', response.total)
    } catch (e) {
      if (!e.cancelled) {
        commit('Notify/setError', 'При загрузке списка команд произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async createTeam({ commit }, payload) {
    try {
      const response = await axios.post('/teams', payload)
      commit('setAddTeam', response)
      commit('Notify/setSuccess', 'Команда успешно сохранена.', { root: true })
    } catch (e) {
      commit('Notify/setError', 'При создании команды произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async deleteTeam({ commit }, id) {
    try {
      await axios.delete(`/teams/${id}`)
      commit('Notify/setSuccess', 'Команда успешно удалена.', { root: true })
      return true
    } catch (e) {
      commit('Notify/setError', 'При удалении команды произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async updateTeam({ commit }, payload) {
    try {
      const id = payload.id
      delete payload.id
      await axios.patch(`/teams/${id}`, payload.model)
      commit('Notify/setSuccess', 'Команда успешно обновлена.', { root: true })
      return true
    } catch (e) {
      commit('Notify/setError', 'При обновлении команды произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
}
