export default {
  ganttList: state => state.ganttList,
  updatedGantt: state => state.updatedGantt,
  taskGantt: state => state.taskGantt,
  dateDynamicTaskGantt: state => state.dateDynamicTaskGantt,
  hasTasksForUpdate: state => state.hasTasksForUpdate,
  taskWasUpdated: state => state.taskWasUpdated,
  isGanttShow: state => state.isGanttShow,
  taskGanttPause: state => state.taskGanttPause,
  plannedStart: state => state.plannedStart,
  plannedEnd: state => state.plannedEnd,
  selectedGanttTask: state => state.selectedGanttTask,
  updateDatesProjectWithTasks: state => state.updateDatesProjectWithTasks,
  currentProjectId: state => state.currentProjectId,
  ganttColumns: state => state.ganttColumns,
  hasFilteredTasks: state => state.hasFilteredTasks,
  criticalPathTasks: state => state.criticalPathTasks,
  isCopyAction: state => state.isCopyAction,
  resourcesAssigned: state => state.resourcesAssigned,
}
