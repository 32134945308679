export default {
  setTeams: (state, data) => (state.teams = data),
  setPerPage: (state, data) => (state.perPage = data),
  setTotalRows: (state, data) => (state.totalRows = data),
  setSelectedTeam: (state, value) => (state.selectedTeam = value),
  setNeedOpenModalForTeam: (state, value) => (state.needOpenModalForTeam = value),
  setAddTeam(state, newTeam) {
    state.teams.unshift(newTeam)
  },
}
