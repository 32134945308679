import mutations from './mutations'

const state = {
  isShow: false,
  isDownloading: false,
}

export default {
  namespaced: true,
  state,
  mutations,
}
