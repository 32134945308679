import axios from '@/store/axios'

export default {
  async downloadPreorderInstrumentsList({ commit }, payload) {
    // Загрузка списка инструментов для предзаказа
    // TODO: Добавить отмену запроса с помощью AbortController
    try {
      const response = await axios.get(`foreman/preorders${payload.args}`)
      commit('setInstruments', response.data)
      commit('setPerPage', response.per_page)
      commit('setTotalRows', response.total)
    } catch (e) {
      if (!e.cancelled) {
        commit('Notify/setError', 'При загрузке списка инструментов произошла ошибка. Попробуйте позже.', {
          root: true,
        })
      }
    }
  },
  async uploadNewPreorderInstrument({ state, commit }) {
    // Добавление нового инструмента в список для предзаказа
    const data = new FormData()
    data.set('instrument_id', state.addedInstrument.id)
    data.set('quantity', state.addedInstrument.quantity)
    try {
      await axios.post('foreman/preorders', data)
      commit('setNeedReloadList', true)
      commit('Notify/setSuccess', 'Инструмент добавлен', { root: true })
      return true
    } catch (e) {
      if (!e.logout) {
        commit('Notify/setError', 'При добавлении произошла ошибка. Попробуйте позже.', { root: true })
        return false
      }
    }
  },

  async uploadEditedPreorderInstrument({ commit }, payload) {
    // Редактирование инструмента в списке для предзаказа

    const { id, quantity } = payload
    const data = new FormData()
    data.set('quantity', quantity)
    try {
      await axios.put(`foreman/preorders/${id}`, data)
      commit('setQuantity', payload)
      commit('Notify/setSuccess', 'Количество отредактировано', { root: true })
      return true
    } catch (e) {
      if (!e.logout) {
        commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
        return false
      }
    }
  },
  async uploadDeletedPreorderInstrument({ commit }, payload) {
    // Удаление инструмента из списка для предзаказа
    const { id, group } = payload
    const data = new FormData()
    data.set('preorders[0]', id)
    try {
      await axios.post('foreman/preorders/delete', data)
      commit('unselectInstrument', id)
      if (!group) {
        commit('setNeedReloadList', true)
        commit('Notify/setSuccess', 'Инструмент удален', { root: true })
      }
      return true
    } catch (e) {
      if (!e.logout) {
        commit('Notify/setError', 'При удалении произошла ошибка. Попробуйте позже.', { root: true })
        return false
      }
    }
  },
  async uploadDeletedGroupPreorderInstrument({ state, commit }) {
    // Групповое удаление инструментов из списка для предзаказа
    const deletedIds = state.selectedInstruments.map(item => item.id)
    try {
      const data = new FormData()
      deletedIds.forEach((id, idx) => data.set(`preorders[${idx}]`, id))
      await axios.post('foreman/preorders/delete', data)
      commit('setNeedReloadList', true)
      commit('Notify/setSuccess', 'Инструменты удалены', { root: true })
      return true
    } catch (e) {
      if (!e.logout) {
        commit('Notify/setError', 'При удалении произошла ошибка. Попробуйте позже.', { root: true })
        return false
      }
    }
  },
  async uploadConfirmedPreorder({ commit }) {
    // Подверждение списка предзаказа / отправка на заказ
    try {
      await axios.post('foreman/orders')
      commit('Notify/setSuccess', 'Список инструментов отправлен для заказа', { root: true })
      return true
    } catch (e) {
      if (!e.logout) {
        commit('Notify/setError', 'При отправке заказа произошла ошибка. Попробуйте позже.', { root: true })
        return false
      }
    }
  },
}
