import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const state = {
  employees: [],
  selectedEmployee: undefined,
  perPage: undefined,
  totalRows: undefined,
  employeesWithoutRfid: [],
  employeeWithoutRfid: null,
  scannedNewRfidPass: null,
  scannedEmployeePass: null,
  availableResponsibleList: [],
  responsiblePerPage: 1,
  responsibleTotalRows: 0,
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
