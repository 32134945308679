import { createStore } from 'vuex'
import Setting from './Setting'
import Auth from './Auth'
import Instrument from './Instrument'
import Employee from './Employee'
import Education from './Education'
import Equipment from './Equipment'
import Selector from './Selector'
import Module from './Module'
import Checkpoint from './Checkpoint'
import Notify from './Notify'
import Notifications from './Notifications'
import Configs from './Configs'
import Dashboard from './Dashboard'
import StatusHistory from './StatusHistory'
import Assignment from './Assignment'
import Extinguisher from './Extinguisher'
import TaskList from './TaskList'
import Disposal from './Disposal'
import UserList from './UserList'
import Interface from './Interface'
import Warehouse from './Warehouse'
import Preorder from './Preorder'
import Search from './Search'
import SearchGlobal from './SearchGlobal'
import Order from './Order'
import User from './User'
import WorkAreas from './WorkAreas'
import VideoSources from './VideoSources'
import ProjectsList from './ProjectsList'
import WriteOff from './WriteOff'
import Positions from './Positions'
import Roles from './Roles'
import Teams from './Teams'
import Permissions from './Permissions'
import Gantt from './Gantt'
import Project from './Project'
import Task from './Task'
import VideoSync from './VideoSync'

// const debug = process.env.NODE_ENV !== 'production'

const store = createStore({
  strict: false,
  modules: {
    Setting,
    Auth,
    Instrument,
    Employee,
    Education,
    Equipment,
    Selector,
    Module,
    Checkpoint,
    Notify,
    Notifications,
    Configs,
    Dashboard,
    StatusHistory,
    Extinguisher,
    Assignment,
    TaskList,
    Disposal,
    UserList,
    Interface,
    Warehouse,
    Preorder,
    Search,
    SearchGlobal,
    Order,
    User,
    WorkAreas,
    VideoSources,
    ProjectsList,
    WriteOff,
    Positions,
    Roles,
    Teams,
    Permissions,
    Gantt,
    Project,
    Task,
    VideoSync,
  },
})

export default store
