export default {
  setModules(state, value) {
    state.modules = value
  },
  setModule(state, value) {
    state.module = value
  },
  setWarehouseInModule(state, value) {
    state.warehouseInModule = value
  },
  setSelectedModule(state, value) {
    state.selectedModule = value
  },
  setAvailableModules(state, data) {
    state.availableModules = data
  },
  setAvailableModule(state, data) {
    state.availableModule = data
  },
  setNeedReloadModules(state, data) {
    state.needReloadModules = data
  },
  setNeedOpenModalForNewModule(state, value) {
    state.needOpenModalForNewModule = value
  },
  setPerPage(state, data) {
    state.perPage = data
  },
  setWarehousePerPage(state, data) {
    state.warehousePerPage = data
  },
  setTotalRows(state, data) {
    state.totalRows = data
  },
  setWarehouseTotalRows(state, data) {
    state.warehouseTotalRows = data
  },
  setRegistryModule(state, value) {
    state.registryModule = value
  },
  updateModule(state, newModule) {
    const modules = state.modules
    const foundModule = modules.find(item => item.id === newModule.id)
    newModule._showDetails = !!foundModule._showDetails
    state.modules = state.modules.map(item => (item.id === foundModule.id ? newModule : item))
  },
}
