import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { getShownColumnsKeys, uuidv4 } from '@/Utils/helpers'

const getColumns = () => {
  const initColumns = [
    { label: 'ID', key: 'id', class: 'text-center', tdClass: 'table-td-id', fixed: true },
    { label: 'Марка огнетушителя', key: 'name', class: 'text-left', tdClass: 'table-td-name', fixed: true },
    { label: 'Категория', key: 'storage', class: 'text-left', tdClass: 'table-td-category' },
    { label: 'Здание', key: 'location', class: 'text-center', tdClass: 'table-td-storage' },
    { label: 'Ответственный', key: 'responsible', class: 'text-left', tdClass: 'table-td-responsible' },
    { label: 'Масса (кг)', key: 'weight', class: 'text-center', tdClass: 'table-td-module' },
    { label: 'Давление (МПа)', key: 'pressure', class: 'text-center', tdClass: 'table-td-module' },
    { label: 'Дата ТО', key: 'serviced_at', class: 'text-center', tdClass: 'table-td-service' }, // Поле serviced_at с бэка не приходит
    { label: 'Дата следующего ТО', key: 'next_serviced_at', class: 'text-center', tdClass: 'table-td-service' }, // Поле next_serviced_at с бэка не приходит
    { label: 'Статус', key: 'status', class: 'text-center', tdClass: 'table-td-status' },
  ]
  const initColumnsKeys = getShownColumnsKeys('extinguisher')
  return initColumns.map(item => {
    if (item.fixed) return item
    const show = !initColumnsKeys || initColumnsKeys.includes(item.key)
    return { ...item, show }
  })
}

const state = {
  columns: getColumns(),
  extinguishers: [],
  extinguisher: {
    id: null,
    message: '',
    name: '',
    RFID: uuidv4(),
    inventory_number: uuidv4(),
    position_number: uuidv4(),
    responsible_id: null,
    supervisor_id: null,
    responsible: {
      id: null,
      first_name: '',
      last_name: '',
    },
    supervisor: {
      id: null,
      first_name: '',
      last_name: '',
    },
    module_number: null,
    arrival_at: '2011-04-25',
    supply_at: '2011-04-25',
    editable: true,
    status_id: null,
    status: {
      id: null,
      title: '',
    },
    storage_id: null,
    storage: {
      id: null,
      title: '',
    },
    location_id: null,
    location: {
      id: null,
      title: '',
    },
  },
  needReloadExtinguishers: false,
  extinguisherListColumns: undefined,
  perPage: undefined,
  totalRows: undefined,
  scannedExtinguishers: [],
  assignModuleMode: '',
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
