<script>
export default {
  name: 'CreatedAtIcon',
  props: ['size', 'color'],
}
</script>

<template>
  <svg
    :width="size || 24"
    :height="size || 24"
    :style="{ 'min-height': size || 24 + 'px !important' }"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 2V5"
      stroke="#3F8CFF"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 2V5"
      stroke="#3F8CFF"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.5 9.08997H20.5"
      stroke="#3F8CFF"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18 23C20.2091 23 22 21.2091 22 19C22 16.7909 20.2091 15 18 15C15.7909 15 14 16.7909 14 19C14 21.2091 15.7909 23 18 23Z"
      stroke="#3F8CFF"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.4898 19.0499H16.5098"
      stroke="#3F8CFF"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18 17.59V20.58"
      stroke="#3F8CFF"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21 8.5V16.36C20.27 15.53 19.2 15 18 15C15.79 15 14 16.79 14 19C14 19.75 14.21 20.46 14.58 21.06C14.79 21.42 15.06 21.74 15.37 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
      stroke="#3F8CFF"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M11.9955 13.7H12.0045" stroke="#3F8CFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8.29431 13.7H8.30329" stroke="#3F8CFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8.29431 16.7H8.30329" stroke="#3F8CFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>
