const Layout = () => import('@/layouts/Layout')
const userChildRoute = prop => [
  {
    path: 'profile',
    name: prop + '.profile',
    title: 'Мой профиль',
    meta: { auth: true, name: 'Profile' },
    component: () => import('@/views/Profile.vue'),
  },
]

export default [
  {
    path: '/user',
    name: 'user',
    title: 'Пользователь',
    component: Layout,
    meta: { auth: true },
    children: userChildRoute('user'),
  },
]
