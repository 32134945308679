import axios from '@/store/axios'

export default {
  async downloadDisposalList({ commit }) {
    try {
      const response = await axios.get('/foreman/disposal')
      const list = response.data
      commit('setDisposalList', list)
      commit('setDisposalCount', list)
      return list
    } catch (e) {
      console.log(e)
    }
  },
}
