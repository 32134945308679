export default {
  setInstruments(state, data) {
    state.instruments = data
  },
  setPerPage(state, data) {
    state.perPage = data
  },
  setTotalRows(state, data) {
    state.totalRows = data
  },
  setQuantity(state, payload) {
    const { id, quantity } = payload
    state.instruments.find(item => item.id === id).quantity = quantity
  },
  clearSelectedInstruments(state) {
    state.selectedInstruments = []
  },
  selectInstrument(state, item) {
    state.selectedInstruments.push(item)
  },
  unselectInstrument(state, id) {
    state.selectedInstruments = state.selectedInstruments.filter(item => item.id !== id)
  },
  setAddedInstrument(state, value) {
    state.addedInstrument = value
  },
  setQuantityForAddedInstrument(state, value) {
    state.addedInstrument.quantity = value
  },
  setNeedReloadList(state, value) {
    state.needReloadList = value
  },
}
