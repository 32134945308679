export default {
  myTasksResponsible: state => state.myTasksResponsible,
  tasksWasDownload: state => state.tasksWasDownload,
  taskHasUpdated: state => state.taskHasUpdated,
  hasAddedTaskInStatusDone: state => state.hasAddedTaskInStatusDone,
  diffInstruments: state => state.diffInstruments,
  changeStatus: state => state.changeStatus,
  filters: state => state.filters,
  search: state => state.search,
  statusesTmc: state => state.statusesTmc,
  task: state => state.task,
  tasks: state => state.tasks,
  myTasksToday: state => state.myTasksToday,
  subtaskGantt: state => state.subtaskGantt,
  allTasks: state => state.allTasks,
  isLoading: state => state.loading,
  equivalentInstruments: state => state.equivalentInstruments,
  historyTasksTMC: state => state.historyTasksTMC,
  selectedTask: state => state.selectedTask,
  addFormStepLocked: state => state.addFormStepLocked,
  taskCardScrolledOut: state => state.taskCardScrolledOut,
  mainTaskSearch: state => state.mainTaskSearch,
  personalList: state => state.personalList,
  tmcList: state => state.tmcList,
  comments: state => state.task.comments,
  projects: state => state.projects,
  equipments: state => state.equipments,
  perPage: state => state.perPage,
  assignedPerPage: state => state.assignedPerPage,
  responsiblePerPage: state => state.responsiblePerPage,
  currentPage: state => state.currentPage,
  totalRows: state => state.totalRows,
  expiredTaskId: state => state.expiredTaskId,
  dateDynamicTaskGantt: state => state.dateDynamicTaskGantt,
  hasDelete: state => state.hasDelete,
  mergedTaskList: state => state.mergedTaskList,
  isMergingTasksMode: state => state.isMergingTasksMode,
  currentPagePlan: state => state.currentPagePlan,
  currentPageDone: state => state.currentPagePlan,
  currentPageProgress: state => state.currentPagePlan,
  currentPageVerify: state => state.currentPagePlan,
  currentPageSearch: state => state.currentPageSearch,
  tasksColorStatuses: state => state.tasksColorStatuses,
}
