import axios from '@/store/axios'

export default {
  async downloadListEmployees({ commit }, payload) {
    try {
      const response = await axios.get(`security/users?${payload.args}`)
      commit('setEmployees', response.data)
      commit('setPerPage', response.per_page)
      commit('setTotalRows', response.total)
    } catch (e) {
      commit('Notify/setError', 'При загрузке списка пользователей произошла ошибка. Попробуйте позже.', { root: true })
      throw e
    }
  },
  clearListEmployees({ commit }) {
    commit('setEmployees', [])
    commit('setPerPage', undefined)
    commit('setTotalRows', undefined)
  },
  async downloadEmployeesWithoutRfid({ commit }, search) {
    // TODO: Добавить отмену запроса с помощью AbortController
    try {
      const searchParam = search ? `search=${search}` : ''
      const response = await axios.get(`/integration/users?${searchParam}`)
      commit('setEmployeesWithoutRfid', response.data)
    } catch (e) {
      if (!e.cancelled) {
        commit('Notify/setError', 'При загрузке списка сотрудников произошла ошибка. Попробуйте позже.', { root: true })
      }
      throw e
    }
  },
  async uploadNewEmployeeWithRfid({ state, commit }, userId) {
    try {
      const rfidNumber = state.scannedNewRfidPass
      const data = new FormData()
      data.set('user_id', userId)
      const response = await axios.put(`/integration/reader/pass/${rfidNumber}`, data)
      console.log(response)
    } catch (e) {
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
      throw e
    }
  },
  async downloadResponsibleList({ commit }, payload) {
    const options = {}
    const page = payload ? payload.page : 1
    const search = payload ? payload.search : ''
    const searchArg = search ? '&search=' + search : ''
    if (payload && payload.signal) {
      options.signal = payload.signal
    }
    try {
      const response = await axios.get(`storekeeper/users?page=${page}${searchArg}`, options)
      commit('setAvailableResponsibleList', response.data)
      commit('setAvailableResponsibleMeta', {
        total: response.total,
        perPage: response.per_page,
      })
    } catch (e) {
      commit('Notify/setError', 'При загрузке списка сотрудников произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async uploadNewEmployee({ commit }, model) {
    try {
      const data = new FormData()
      for (const [key, value] of Object.entries(model)) {
        if (!value) continue
        if (key === 'images') {
          value.map((image, index) => {
            data.set(`image[${index}]`, image.file)
            return image
          })
          continue
        }
        data.set(key, String(value))
      }
      const response = await axios.post('security/users', data)
      commit('setSelectedEmployee', {
        ...response,
        roles: [],
      })
      commit('Notify/setSuccess', `Пользователь ${response.last_name} успешно добавлен`, { root: true })
      return true
    } catch (e) {
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async uploadEditedEmployee({ state, commit }, payload) {
    try {
      const { id, model } = payload
      const data = new FormData()
      for (const [key, value] of Object.entries(model)) {
        data.set(key, String(value))
      }
      await axios.put(`security/users/${id}`, data)
      const updatedUser = {
        ...state.selectedEmployee,
        ...model,
      }
      commit('setSelectedEmployee', updatedUser)
      return true
    } catch (e) {
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async uploadDeletedEmployee({ state, commit }) {
    try {
      const id = state.selectedEmployee.id
      await axios.delete(`security/users/${id}`)
      return true
    } catch (e) {
      commit('Notify/setError', 'При удалении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
}
