import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const getColumns = () => {
  return [
    { label: 'Номер', key: 'idx', class: 'number text-center', fixed: true },
    { label: 'Название', key: 'name', class: 'text-left', tdClass: 'table-td-name', fixed: true },
    { label: 'Модель', key: 'model', class: 'text-center', tdClass: 'table-td-model' },
    {
      label: 'Периодичность ремонта в мес.',
      key: 'repair_frequency',
      class: 'text-center',
      tdClass: 'table-td-frequency',
    },
    { label: 'Глубина планирования в годах', key: 'repair_range', class: 'text-center', tdClass: 'table-td-range' },
    {
      label: 'Регистрационный номер',
      key: 'registration_number',
      class: 'text-center',
      tdClass: 'table-td-registration',
    },
  ]
}

const state = {
  columns: getColumns(),
  equipments: [],
  selectedEquipment: undefined,
  perPage: null,
  totalRows: null,
  needOpenModalEquipment: false,
}
export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
