export default [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/layouts/OnlyHeaderLayout'),
    children: [
      {
        path: '1',
        component: () => import('@/views/Dashboard1.vue'),
      },
      {
        path: '2',
        component: () => import('@/views/Dashboard2.vue'),
      },
      {
        path: '3',
        component: () => import('@/views/Dashboard3.vue'),
      },
      {
        path: 'pre-repair',
        component: () => import('@/components/views/Pages/Dashboard/DashboardHardcode'),
      },
    ],
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('@/layouts/OnlyHeaderLayout'),
    children: [
      {
        path: 'instruments',
        component: () => import('@/components/views/Search/Instruments'),
      },
    ],
  },
  {
    path: '/horizon-notifications',
    name: 'Горизонт уведомления',
    component: () => import('@/layouts/HeaderLayout.vue'),
    children: [
      {
        path: '/',
        component: () => import('@/views/common/HorizonNotification.vue'),
      },
    ],
  },
  {
    path: '/stream-video',
    name: 'Стриминг видео',
    // component: () => import('@/layouts/OnlyHeaderLayout'),
    children: [
      {
        path: 'test',
        component: () => import('@/views/Custom.vue'),
      },
    ],
  },
]
