export default {
  selectedImage: state => state.selectedImage,
  selectedCombination: state => state.selectedCombination,
  images: state => state.images,
  combinations: state => state.combinations,
  videoSources: state => state.videoSources,
  videoSourcesTotal: state => state.videoSourcesTotal,
  isSendDataLoading: state => state.isSendDataLoading,
  isSaveDataLoading: state => state.isSaveDataLoading,
  isDataDownload: state => state.isDataDownload,
}
