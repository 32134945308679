export const getSortedInstrumentList = instruments => {
  return instruments.sort((a, b) => {
    // Создаем уникальные ключи для каждого элемента
    const keyA = `${a.id}-${a.name}`
    const keyB = `${b.id}-${b.name}`

    // Сравниваем ключи
    return keyA.localeCompare(keyB)
  })
}
