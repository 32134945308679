import axios from '@/store/axios'

export default {
  async downloadListPositions({ commit }, payload) {
    try {
      const response = await axios.get('/admin/positions' + `${payload.args}`)
      commit('setPositions', response.data)
      commit('setPerPage', response.per_page)
      commit('setTotalRows', response.total)
    } catch (e) {
      if (!e.cancelled) {
        commit('Notify/setError', 'При загрузке списка должностей произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async uploadNewPosition({ commit }, data) {
    try {
      const response = await axios.post('/admin/positions', data)
      commit('setSelectedPosition', {
        ...response,
      })
      commit('Notify/setSuccess', 'Должность успешно добавлена', { root: true })
      return true
    } catch (errors) {
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async uploadEditedPosition({ state, commit }, payload) {
    const { id, model } = payload
    try {
      const response = await axios.put(`/admin/positions/${id}`, model)
      const updatedPosition = {
        ...state.selectedPosition,
        ...response,
      }
      commit('setSelectedPosition', updatedPosition)
      commit('Notify/setSuccess', 'Должность успешно изменена', { root: true })
      return true
    } catch (e) {
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async uploadDeletedPosition({ commit }, id) {
    try {
      await axios.delete(`/admin/positions/${id}`)
      return true
    } catch (e) {
      commit('Notify/setError', 'При удалении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
}
