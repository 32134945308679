import moment from 'moment'

export function getErrorMessage(error) {
  let result = []
  const errors = error.data.errors
  const message = error.data.message
  if (errors) {
    for (const value of Object.values(errors)) {
      result = [...result, value]
    }
  } else if (message) {
    result.push(message)
  }
  return result
}

export function toSentenceFormat(value) {
  if (!value) return ''
  return value[0].toUpperCase() + value.slice(1).toLowerCase()
}
export const calculateAcceptUserRoutes = function (menuItems, userRoutes) {
  const findRouteByPath = (routes, path) => {
    for (const route of routes) {
      if (route.permissionsPath === path) {
        return route
      } else if (route.children) {
        const foundChild = findRouteByPath(route.children, path)
        if (foundChild) {
          return foundChild
        }
      }
    }
    return null
  }

  const filterRoutesRecursive = routes => {
    const filteredRoutes = []
    routes.forEach(route => {
      if (route.children && route.children.length > 0) {
        const filteredChildren = filterRoutesRecursive(route.children)
        if (filteredChildren.length === 1) {
          filteredRoutes.push(findRouteByPath(menuItems, filteredChildren[0].permissionsPath))
        } else if (filteredChildren.length > 0) {
          filteredRoutes.push({
            ...route,
            children: filteredChildren,
          })
        }
      } else if (userRoutes.includes(route.permissionsPath)) {
        filteredRoutes.push(findRouteByPath(menuItems, route.permissionsPath))
      }
    })
    return filteredRoutes
  }

  return filterRoutesRecursive(menuItems)
}
export function matchPercentage(line1, line2) {
  line1 = line1.toLowerCase()
  line2 = line2.toLowerCase()
  const m = line1.length
  const n = line2.length
  if (m === 0 && n === 0) return 100
  const matrix = Array.from(Array(m + 1), () => Array(n + 1).fill(0))
  for (let i = 0; i <= m; i++) matrix[i][0] = i
  for (let j = 0; j <= n; j++) matrix[0][j] = j
  for (let i = 1; i <= m; i++) {
    for (let j = 1; j <= n; j++) {
      if (line1[i - 1] === line2[j - 1]) matrix[i][j] = matrix[i - 1][j - 1]
      else {
        matrix[i][j] = Math.min(matrix[i - 1][j - 1] + 1, matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
      }
    }
  }
  return ((m + n - matrix[m][n]) / (m + n)) * 100
}

export function deleteAllCookies() {
  const setCookie = (name, value, expirydays) => {
    const currentDomain = window.location.hostname
    const d = new Date()
    d.setTime(d.getTime() + expirydays * 24 * 60 * 60 * 1000)
    const cookieValue = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`
    const expires = `expires=${d.toUTCString()}`
    const domain = `domain=${currentDomain}`
    document.cookie = `${cookieValue}; ${expires}; path=/; ${domain}`
  }

  const deleteCookie = name => {
    setCookie(name, '', -1)
  }

  const cookies = document.cookie.split(';')
  for (const cookie of cookies) {
    deleteCookie(cookie.split('=')[0])
  }
}

export function transliteration(word) {
  const converter = {
    а: 'a',
    б: 'b',
    в: 'v',
    г: 'g',
    д: 'd',
    е: 'e',
    ё: 'e',
    ж: 'zh',
    з: 'z',
    и: 'i',
    й: 'y',
    к: 'k',
    л: 'l',
    м: 'm',
    н: 'n',
    о: 'o',
    п: 'p',
    р: 'r',
    с: 's',
    т: 't',
    у: 'u',
    ф: 'f',
    х: 'h',
    ц: 'c',
    ч: 'ch',
    ш: 'sh',
    щ: 'sch',
    ь: '',
    ы: 'y',
    ъ: '',
    э: 'e',
    ю: 'yu',
    я: 'ya',
  }
  word = word.toLowerCase()
  let answer = ''
  for (let i = 0; i < word.length; ++i) {
    if (converter[word[i]] === undefined) {
      answer += word[i]
    } else {
      answer += converter[word[i]]
    }
  }
  answer = answer.replace(/[^-0-9a-z]/g, '-')
  answer = answer.replace(/[-]+/g, '-')
  answer = answer.replace(/^-|-$/g, '')
  return answer
}

export function getShownColumnsKeys(type) {
  const user = localStorage.getItem('user')
  if (!user) return undefined
  const userId = JSON.parse(user).id
  const localData = localStorage.getItem(`${type}ListColumns`)
  if (!localData) return undefined
  const parsedData = JSON.parse(localData)
  return parsedData[userId]
}

export function updateShownColumnsKeys(type, value) {
  const user = localStorage.getItem('user')
  if (!user) return undefined
  const userId = JSON.parse(user).id
  const newRecord = {
    [userId]: value,
  }
  const fieldName = `${type}ListColumns`
  const localData = localStorage.getItem(fieldName)
  if (!localData) {
    localStorage.setItem(fieldName, JSON.stringify(newRecord))
    return
  }
  const parsedData = JSON.parse(localData)
  const updatedData = {
    ...parsedData,
    ...newRecord,
  }
  localStorage.setItem(fieldName, JSON.stringify(updatedData))
}

export function formatDateToRussianPattern(date) {
  return moment(date).format('DD.MM.YY')
}

export function formatTime(date) {
  return moment(date).format('HH:mm')
}

export function formatDateToListPattern(date) {
  if (!date) return '-'
  const _date = new Date(date)
  return moment(_date).format('YYYY-MM-DD')
}

export function formatDateTimeToListPattern(date) {
  if (!date) return '-'
  const _date = new Date(date)
  return moment(_date).format('YYYY-MM-DD HH:mm:ss')
}

export function formatDateTimeToRussianListPattern(date) {
  if (!date) return '-'
  const _date = new Date(date)
  return moment(_date).format('DD.MM.YY HH:mm:ss')
}

export function formatTimeToListPattern(date) {
  if (!date) return ''
  const _date = new Date(date)
  return moment(_date).format('HH:mm:ss')
}

export function diffTimeToListPattern(from, to) {
  if (!from) return ''
  if (!to) return ''
  const _from = moment(new Date(from))
  const _to = moment(new Date(to))
  return _to.diff(_from, 'minutes') + 'm'
}

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export function getAvatarColor(assignee) {
  const MIN = 90
  const MAX = 180
  const getColor = value => Math.max(MIN, Math.min(MAX, value))
  const k1 = assignee.id ? assignee.id.replaceAll(/\D/g, '') : assignee.replaceAll(/\D/g, '')
  const arg1 = ((k1 % 10) + 1) * 25
  const k2 = assignee.id ? assignee.id.replaceAll(/\D/g, '') : assignee.replaceAll(/\D/g, '')
  const arg2 = ((k2.slice(1, 5) % 10) + 1) * 25
  const k3 = assignee.id ? assignee.id.replaceAll(/\D/g, '') : assignee.replaceAll(/\D/g, '')
  const arg3 = ((k3.slice(3, 9) % 10) + 1) * 25

  return `rgb(${getColor(arg1)},${getColor(arg2)},${getColor(arg3)})`
}

export function getAvatarNewColor(assignee) {
  const MIN = 90
  const MAX = 180
  const getColor = value => Math.max(MIN, Math.min(MAX, value))
  const k1 = assignee.id ? assignee.id.replaceAll(/\D/g, '') : assignee.replaceAll(/\D/g, '')
  const arg1 = ((k1 % 10) + 1) * 25
  const k2 = assignee.id ? assignee.id.replaceAll(/\D/g, '') : assignee.replaceAll(/\D/g, '')
  const arg2 = ((k2.slice(1, 5) % 10) + 1) * 25
  const k3 = assignee.id ? assignee.id.replaceAll(/\D/g, '') : assignee.replaceAll(/\D/g, '')
  const arg3 = ((k3.slice(3, 9) % 10) + 1) * 25

  return `rgba(${getColor(arg1)},${getColor(arg2)},${getColor(arg3)},0.2)`
}

export function convertFileFromInput(file, callback) {
  const convert = new Promise(resolve => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      if (reader.result) {
        resolve(reader.result.toString() || '')
      }
    }
  })
  convert.then(base64String => {
    const result = {
      file,
      name: file.name,
      type: file.type,
      path: base64String,
    }
    callback(result)
  })
}

export function capitalize(value) {
  return value[0].toUpperCase() + value.slice(1).toLowerCase()
}

// Метод принимает объект типа {done: [], open: [], progress: [], verify: []}, который содержит все задачи в разных статусах
// И задачу, верхушку иерархии (ветки) которой мы хотим найти
// Метод возвращает верхнюю задачу в иерархии (ветки)
export function getMainTask(allTask, childTask) {
  if (childTask.state_id) {
    const parentTask = findParentTask(allTask, childTask.state_id)

    if (parentTask.state_id) {
      return getMainTask(allTask, parentTask)
    } else {
      return parentTask
    }
  } else {
    return childTask
  }
}

// Метод принимает задачу, внутри которой мы хотим найти другие задачи с определённым статусом
// И статус по которому мы хотим отобрать задачи
// Метод возвращает массив задач с нужным нам статусом, которые являются крайними (последними) в ветке (иерархии) задач
// Т.к. нам именно они влияют на перевод статусов своих родительских задач
export function findTaskWithStatus(mainTask, status) {
  const statusTasks = []

  // Проверяем текущую задачу
  if (mainTask.status && mainTask.status.name === status && mainTask.subtasks.length === 0) {
    statusTasks.push(mainTask)
  }

  // Проверяем подзадачи
  if (mainTask.subtasks && mainTask.subtasks.length > 0) {
    for (const subtask of mainTask.subtasks) {
      statusTasks.push(...findTaskWithStatus(subtask, status))
    }
  }

  return statusTasks
}

// Метод принимает задачу, внутри которой мы хотим найти другие задачи без выбранного статуса
// И статус по которому мы хотим отобрать задачи
// Метод возвращает массив задач со статусами отличными от аргумента метода, которые являются крайними (последними) в ветке (иерархии) задач
// Т.к. нам именно они влияют на перевод статусов своих родительских задач
export function findTaskWithoutStatus(mainTask, status) {
  const statusTasks = []

  // Проверяем текущую задачу
  if (mainTask.status && mainTask.status.name !== status && mainTask.subtasks.length === 0) {
    statusTasks.push(mainTask)
  }

  // Проверяем подзадачи
  if (mainTask.subtasks && mainTask.subtasks.length > 0) {
    for (const subtask of mainTask.subtasks) {
      statusTasks.push(...findTaskWithoutStatus(subtask, status))
    }
  }

  return statusTasks
}

export function findParentTask(list, parentTaskId) {
  // Находит задачу в списке с учетом вложенности для объекта
  if (!list) return
  const tasks = Object.values(list).flat()

  const findMainTask = (taskArray, parentTaskId) => {
    for (const task of taskArray) {
      if (task.id === parentTaskId) {
        return task
      } else if (task.subtasks && task.subtasks.length > 0) {
        const mainTask = findMainTask(task.subtasks, parentTaskId)
        if (mainTask) {
          return mainTask
        }
      }
    }
    return null
  }

  return findMainTask(tasks, parentTaskId)
}

export function findParentTaskFromList(list, parentTaskId) {
  // Находит задачу в списке с учетом вложенности для массива
  if (!list) return
  const tasks = list

  const findMainTask = (taskArray, parentTaskId) => {
    for (const task of taskArray) {
      if (task.id === parentTaskId) {
        return task
      } else if (task.subtasks && task.subtasks.length > 0) {
        const mainTask = findMainTask(task.subtasks, parentTaskId)
        if (mainTask) {
          return mainTask
        }
      }
    }
    return null
  }

  return findMainTask(tasks, parentTaskId)
}

export function getRoleColorByUuid(uuid, opacity = true) {
  const digits = uuid.replaceAll(/[\D,0]+/g, '')
  const num = parseInt(digits.slice(digits.length - 9))
  const offset = num % 2 ? 10 : 30
  const k1 = Math.floor(num) % (256 - offset * 2)
  const k2 = Math.floor(num / 2) % (256 - offset * 2)
  const k3 = Math.floor(num / 3) % (256 - offset * 2)

  return `rgba(${k1 + offset},${k2 + offset},${k3 + offset},${opacity ? 0.4 : 1})`
}

export function debounce(fn, ms) {
  let timeoutId

  return function (...args) {
    clearTimeout(timeoutId)

    return new Promise(resolve => {
      timeoutId = setTimeout(() => {
        resolve(fn(...args))
      }, ms)
    })
  }
}
