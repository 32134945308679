import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { getShownColumnsKeys } from '@/Utils/helpers'

const getColumns = () => {
  const initColumns = [
    { label: 'Номер', key: 'idx', class: 'number text-center', fixed: true },
    { label: 'Название', key: 'name', class: 'text-left', tdClass: 'table-td-name', fixed: true },
    { label: 'Локация', key: 'locations', class: 'text-center', tdClass: 'table-td-location' },
    { label: 'Ответственный', key: 'users', class: 'text-left', tdClass: 'table-td-responsible' },
    { label: 'Команды', key: 'teams', class: 'text-left', tdClass: 'table-td-responsible' },
    { label: 'Статус', key: 'statuses', class: 'text-center', tdClass: 'table-td-status' },
    { label: 'Метка', key: 'label', class: 'text-center', tdClass: 'table-td-status' },
    { label: 'Модуль', key: 'modules', class: 'text-center', tdClass: 'table-td-module' },
    { label: 'Выдача', key: 'arrival_at', class: 'text-center', tdClass: 'table-td-arrival' },
    { label: 'Приход', key: 'supply_at', class: 'text-center', tdClass: 'table-td-supply' },
    { label: 'Регистрационный номер', key: 'inventory_number', class: 'text-center', tdClass: 'table-td-supply' },
  ]
  const initColumnsKeys = getShownColumnsKeys('warehouse')
  return initColumns.map(item => {
    if (item.fixed) return item
    const show = !initColumnsKeys || initColumnsKeys.includes(item.key)
    return { ...item, show }
  })
}

const state = {
  columns: getColumns(),
  instruments: [],
  instrumentNameFilter: '',
  inspectorInstrument: [],
  inspectorInstrumentInModal: [],
  perPage: undefined,
  totalRows: undefined,
  selectedInstrument: undefined,
  needOpenModalForNewInstrument: false,
  viewFullScreen: true,
  warehouseShow: true,
  isUpdateRegistryStatus: false,
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
