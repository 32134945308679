import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  path: 'Default',
  user: null,
  userRoles: [],
  errors: null,
  isFirstLogin: undefined,
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
