export default {
  setOrders(state, value) {
    state.orders = value
  },
  setPreorders: state => (state.preorders = !state.preorders),
  setCurrentPage(state, value) {
    state.currentPage = value
  },
  setPerPage(state, value) {
    state.perPage = value
  },
}
